////
const { GNP_URL_BASE, GNP_URL_BASE2, GNP_APIKEY, GNP_APIKEY2 } = window["runConfig"];

export const GNP_API_KEY = GNP_APIKEY;
export const GNP_API_KEY2 = GNP_APIKEY2;

export const URL_GNP_GET_SERVICIOS = GNP_URL_BASE + "/asistencia/servicios/estatus";
export const URL_GNP_GET_SERVICIO = GNP_URL_BASE + "/asistencia/servicio";

export const URL_GNP_GET_ESTATUS = GNP_URL_BASE + "/catalagos/estatus";
export const URL_GNP_GET_MOTIVOS = GNP_URL_BASE + "/catalogos/motivo";

export const URL_GNP_POST_SERVICIO = GNP_URL_BASE + "/servicio/asignacion";

export const BASE_URL_GNP = GNP_URL_BASE;
export const BASE_URL_GNP2 = GNP_URL_BASE2;

