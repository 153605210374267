import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  getDistanceBetweenPlaces,
  startPostProveedor,
} from "../../../../../../context/actions/detalleActions";
import {
  hdiTipoFechas,
  putNotification,
} from "../../../../../../context/actions/hdiActions";

export const useForma = () => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const [datos, setDatos] = useState({});


  const distanceInKmBetweenPlacesByApiGoogle = useSelector(
    (state) => state.detalleReducer.distanceInKmBetweenPlacesByApiGoogle
  );

  const {
    esGnpApi,
    latDestino,
    latOrigen,
    lngDestino,
    lngOrigen,
    folioGnp,
    estatusFinalID,
    asegurado,
  } = useSelector((state) => state.detalleReducer.asistencia);

  const {campaniaID} = asegurado
  useEffect(() => {
    dispatch(
      getDistanceBetweenPlaces(
        { lat: parseFloat(latOrigen), lng: parseFloat(lngOrigen) },
        {
          lat: parseFloat(latDestino),
          lng: parseFloat(lngDestino),
        }
      )
    );
  }, [dispatch, lngDestino, lngDestino, lngOrigen, latOrigen]);

  useEffect(() => {
    setDatos({ ...datos, KM: distanceInKmBetweenPlacesByApiGoogle });
  }, [distanceInKmBetweenPlacesByApiGoogle]);

  const [err, setErr] = useState("");

  const handleChange = ({ target }) => {
    setDatos({
      ...datos,
      [target.name]: target.value,
    });
  };

  const handleChangeSelect = (val) => {
    setDatos({
      ...datos,
      localCarretero: val,
    });
  };

  const handleCuentaEspecial = (val) => {
    setDatos({
      ...datos,
      cuentaEspecialGNP: val,
    });
  };

  const handleSwitch = (val, ele) => {
    setDatos({
      ...datos,
      [ele]: val,
    });
  };

  ///////////////////

  const handleClick = (estaAsignado) => {
    // Si el servicio es hdi hace la actualiación (verifica eso en la función)

    setErr("");

    if (
      estaAsignado &&
      (!datos.localCarretero || !datos.tiempoLlegada || !datos.costo)
    ) {
      setErr("Faltan datos");
      return;
    }

    const body = {
      asistenciaID,
      estaAsignado,
      esGnpApi,
      folioGnp,
      ...datos,
    };

    // console.log(body)
    dispatch(startPostProveedor(body)).then(() =>
      dispatch(putNotification(hdiTipoFechas.fechaAsignacion, datos))
    );
  };

  return {
    datos,
    esGnpApi,
    estatusFinalID,
    err,
    campaniaID,
    handleChange,
    handleChangeSelect,
    handleCuentaEspecial,
    handleSwitch,
    handleClick,
  };
};
